// routes
import { PATH_DASHBOARD } from '../../routes/paths';

// ----------------------------------------------------------------------

const NavbarConfig = [
  {
    title: 'Início',
    path: PATH_DASHBOARD.root
  },
  {
    title: 'Dashboard',
    path: PATH_DASHBOARD.dashboarding
  },
  {
    title: 'Meus Processos Seletivos',
    path: PATH_DASHBOARD.selectiveProcesses.root
  },
  {
    title: 'Buscar Candidato',
    path: PATH_DASHBOARD.candidate.root
  },
  {
    title: 'Testes Personalizados',
    path: PATH_DASHBOARD.questionSheet.root
  }
];

export default NavbarConfig;
