// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_SELECTIVE_PROCESS = '/dashboardSelectiveProcess';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  changePassword: path(ROOTS_AUTH, '/change-password')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
  publicTest: {
    valuesTest: {
      intro: (companyId) => `publicTest/${companyId}/valuesTest`,
      answer: (companyId) => `publicTest/${companyId}/valuesTest/answer`
    }
  }
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  dashboarding: path(ROOTS_DASHBOARD, '/dashboarding'),
  helpCenter: path(ROOTS_DASHBOARD, '/helpCenter'),
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking')
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },
  selectiveProcesses: {
    root: path(ROOTS_SELECTIVE_PROCESS, '/selectiveProcesses')
  },
  candidate: {
    root: path(ROOTS_DASHBOARD, '/candidates')
  },
  companies: {
    root: path(ROOTS_SELECTIVE_PROCESS, '/companies'),
    register: path(ROOTS_SELECTIVE_PROCESS, '/companies/register'),
    listSelectiveProcesses: path(ROOTS_SELECTIVE_PROCESS, '/companies/:companyId/listSelectiveProcesses'),
    registerSelectiveProcess: path(ROOTS_SELECTIVE_PROCESS, '/companies/:companyId/registerSelectiveProcess'),
    updateSelectiveProcess: path(ROOTS_SELECTIVE_PROCESS, '/companies/:companyId/updateSelectiveProcess'),
    viewSelectiveProcess: path(
      ROOTS_SELECTIVE_PROCESS,
      '/companies/:companyId/viewSelectiveProcess/:selectivePocessId'
    ),
    editCompany: path(ROOTS_SELECTIVE_PROCESS, '/companies/:companyId/editCompany')
  },
  questionSheet: {
    root: path(ROOTS_DASHBOARD, '/questionSheet'),
    customTest: path(ROOTS_DASHBOARD, '/questionSheet/customTest'),
    profileTestResult: path(ROOTS_DASHBOARD, '/questionSheet/profileTestResult')
  }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
