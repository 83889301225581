import { Outlet } from 'react-router-dom';
// material
import { Divider } from '@material-ui/core';
import { styled, useTheme } from '@material-ui/core/styles';
// hooks
//
import DashboardNavbar from './DashboardNavbar';

// ----------------------------------------------------------------------

const RootStyle = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(() => ({
  paddingTop: '0.25rem'
}));

// ----------------------------------------------------------------------

export default function DashboardSelectiveProcess() {
  const theme = useTheme();

  return (
    <RootStyle>
      <DashboardNavbar />
      <Divider />
      <MainStyle
        sx={{
          transition: theme.transitions.create('margin', {
            duration: theme.transitions.duration.complex
          })
        }}
      >
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
