import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useSnackbar } from 'notistack5';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
// components
import { MIconButton } from '../components/@material-extend';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_DASHBOARD } from '../routes/paths';
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

RoleGuard.propTypes = {
  children: PropTypes.node
};

export default function RoleGuard({ children }) {
  const { user, initialize } = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(!(user && user.isActive));

  useEffect(() => {
    if (user && !user.isActive) initialize().finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading && !user.isActive) {
      enqueueSnackbar('Sua conta não está ativa, assine um plano', {
        variant: 'warning',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loading]);

  if (loading) return <LoadingScreen />;

  if (!user.isActive) {
    return <Navigate to={PATH_DASHBOARD.selectiveProcesses.root} />;
  }

  return <>{children}</>;
}
