import { Outlet } from 'react-router-dom';
import { useState } from 'react';
// material
import { Divider, CircularProgress } from '@material-ui/core';
import { styled, useTheme } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
// hooks
//
import DashboardNavbar from './DashboardNavbar';
import DashboardFooter from './DashboardFooter';

// ----------------------------------------------------------------------

const RootStyle = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(() => ({
  flexGrow: 1,
  paddingTop: '0.25rem',
  overflow: 'auto',
  minHeight: '100%',
  paddingBottom: '4rem'
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const theme = useTheme();
  const [showIframe, setShowIframe] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleIframeLoad = () => {
    setLoading(false);
  };

  const handleCloseModal = () => {
    setShowIframe(false);
    setLoading(false);
  };

  return (
    <RootStyle>
      <DashboardNavbar />
      <Divider />
      <MainStyle
        sx={{
          transition: theme.transitions.create('margin', {
            duration: theme.transitions.duration.complex
          })
        }}
      >
        <Outlet />
      </MainStyle>
      <DashboardFooter />
      <Modal open={showIframe} onClose={handleCloseModal}>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            width: '100%'
          }}
        >
          <div
            style={{
              minWidth: '38%',
              height: '80vh',
              marginTop: '10vh',
              marginBottom: '10vh',
              background: '#fff',
              border: '1px solid #ccc',
              borderRadius: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative'
            }}
          >
            <CloseIcon
              style={{ cursor: 'pointer', position: 'absolute', top: '0', right: '0', zIndex: '999' }}
              onClick={handleCloseModal}
            />
            {loading ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  width: '100%',
                  position: 'absolute'
                }}
              >
                <CircularProgress />
              </div>
            ) : null}
            <iframe
              title="Formulário de contato"
              className="clickup-embed clickup-dynamic-height"
              src="https://forms.clickup.com/31075871/f/xmbgz-22663/L9OQVKOVN27X5MW60O"
              style={{
                maxWidth: '90%',
                maxHeight: '90vh',
                width: '100%',
                height: '100%',
                background: 'transparent',
                border: '1px solid #ccc',
                visibility: loading ? 'hidden' : 'visible'
              }}
              onLoad={handleIframeLoad}
            />
          </div>
        </div>
      </Modal>
    </RootStyle>
  );
}
