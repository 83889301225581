import { Link as ScrollLink } from 'react-scroll';
import { useLocation, Outlet } from 'react-router-dom';
// material
import { Box, Link, Container, Typography, Stack } from '@material-ui/core';
// components
import MainNavbar from './MainNavbar';
import MainFooter from './MainFooter';
import Logo from '../../components/logos/Logo';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();
  const isHome = pathname === '/';
  const handleScrollDown = () => {
    window.scrollBy(0, -900);
  };

  return (
    <>
      <MainNavbar />
      <div>
        <Outlet />
      </div>

      {!isHome ? (
        <MainFooter />
      ) : (
        <>
          <Box
            sx={{
              py: 5,
              textAlign: 'center',
              position: 'relative',
              bgcolor: (theme) => theme.palette.primary.dark
            }}
          >
            <Container maxWidth="lg">
              <Stack direction={{ xs: 'column', lg: 'row' }} spacing={3} justifyContent="space-between">
                <Stack>
                  <Stack alignItems={{ xs: 'center', lg: 'start' }}>
                    <ScrollLink to="move_top" spy smooth>
                      <Logo color="white" />
                    </ScrollLink>
                    {/* <Typography
                      variant="subtitle1"
                      color="common.white"
                      fontWeight="500"
                      sx={{ mb: '1.5rem', mt: '2.375rem' }}
                    >
                      Startup acelerada por:
                    </Typography>
                    <Stack
                      direction={{ xs: 'column', md: 'row' }}
                      alignItems={{ xs: 'center', md: 'start' }}
                      spacing={3}
                      sx={{ mb: '3.438rem' }}
                    >
                      <Stack component="img" src="/static/icons/tecnova.svg" alt="Selo tecnova" />
                      <Stack
                        component="img"
                        src="/static/icons/LOGO_INOVATIVA_Brasil.png"
                        alt="INOVATIVA"
                        sx={{ width: '9.125rem' }}
                      />
                    </Stack> */}
                  </Stack>
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3} justifyContent="center">
                  <Stack alignItems={{ xs: 'center', sm: 'start' }} justifyContent="start">
                    <Typography variant="subtitle1" color="common.white" fontWeight="700" sx={{ mb: '1.5rem' }}>
                      PLATAFORMA
                    </Typography>
                    <Link href={process.env.REACT_APP_CANDIDATE_URL} target="_blank" sx={{ mb: '1rem' }}>
                      <Typography variant="subtitle1" color="primary.lighter" fontWeight="700" sx={{ cursor: 'point' }}>
                        Para Candidatos
                      </Typography>
                    </Link>
                    <Typography
                      variant="subtitle1"
                      color="primary.lighter"
                      fontWeight="700"
                      sx={{ mb: '1rem', cursor: 'pointer' }}
                      onClick={() => window.location.reload()}
                    >
                      Para Empresas
                    </Typography>

                    {/*                     <Typography
                      variant="subtitle1"
                      color="primary.lighter"
                      fontWeight="700"
                      sx={{ mb: '1rem', cursor: 'pointer' }}
                    >
                      FAQ
                    </Typography> */}
                    <Link href="https://web.whatsapp.com/send?phone=5511976732364" target="_blank">
                      <Typography
                        variant="subtitle1"
                        color="primary.lighter"
                        fontWeight="700"
                        sx={{ mb: '1rem', cursor: 'pointer' }}
                      >
                        Fale Conosco
                      </Typography>
                    </Link>

                    <Link onClick={handleScrollDown} target="_blank" sx={{ mb: '2.125rem' }}>
                      <Typography variant="subtitle1" color="primary.lighter" fontWeight="700">
                        Cadastre-se Grátis
                      </Typography>
                    </Link>
                  </Stack>
                  <Stack alignItems={{ xs: 'center', sm: 'start' }} justifyContent="start">
                    <Typography variant="subtitle1" color="common.white" fontWeight="700" sx={{ mb: '1.5rem' }}>
                      LEGAL
                    </Typography>
                    <Link href="static/files/POLÍTICA_DE_PRIVACIDADE_DEALERJOBS.pdf" target="_blank">
                      <Typography variant="subtitle1" color="primary.lighter" fontWeight="700" sx={{ mb: '1rem' }}>
                        Política de Privacidade
                      </Typography>
                    </Link>
                    <Link href="static/files/TERMOS_DE_USO_QUE_VENDE.pdf" target="_blank">
                      <Typography variant="subtitle1" color="primary.lighter" fontWeight="700" sx={{ mb: '1rem' }}>
                        Termos de Uso
                      </Typography>
                    </Link>

                    {/*  <Typography variant="subtitle1" color="primary.lighter" fontWeight="700" sx={{ mb: '2.125rem' }}>
                      Política de Cookies
                    </Typography> */}
                  </Stack>

                  <Stack alignItems={{ xs: 'center', sm: 'start' }} justifyContent="start">
                    <Typography variant="subtitle1" color="common.white" fontWeight="700" sx={{ mb: '1.5rem' }}>
                      CONTATO
                    </Typography>
                    <Typography variant="subtitle1" color="primary.lighter" fontWeight="700" sx={{ mb: '2.125rem' }}>
                      equipe@discoverydealer.com
                    </Typography>
                    <Stack>
                      <Typography variant="subtitle1" color="common.white" fontWeight="700" sx={{ mb: '1.5rem' }}>
                        Siga-nos nas redes sociais
                      </Typography>
                      <Stack direction="row" spacing={2} justifyContent="center" sx={{ mb: '2.125rem' }}>
                        <Link href="https://instagram.com/sigadealerjobs?utm_medium=copy_link" target="_blank">
                          <img src="/static/icons/insta_icon.svg" alt="insta" />
                        </Link>
                        <Link href="https://www.linkedin.com/company/sigadealerjobs" target="_blank">
                          <img src="/static/icons/linkedin_icon.svg" alt="linkedin" />
                        </Link>
                        {/* <Link href="https://web.whatsapp.com/send?phone=5511976732364" target="_blank">
                          <img src="/static/icons/whatsapp_Icon.svg" alt="whatsapp" />
                        </Link> */}
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Container>
          </Box>
          <Box sx={{ textAlign: 'center', position: 'relative', bgcolor: (theme) => theme.palette.primary.darker }}>
            <Container maxWidth="lg">
              <Stack alignItems="center" sx={{ py: '1.125rem' }}>
                <Typography variant="subtitle1" color="common.white" fontWeight="500">
                  DealerJobs © 2022 | Todos os direitos reservados.
                </Typography>
              </Stack>
            </Container>
          </Box>
        </>
      )}
    </>
  );
}
