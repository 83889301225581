import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import RoleGuard from '../guards/RoleGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
import PublicLayout from '../layouts/PublicLayout';
import DashboardSelectiveProcess from '../layouts/dashboard/DashboardSelectiveProcess';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'change-password', element: <ChangePassword /> },
        { path: 'register', element: <Register /> }
      ]
    },

    {
      path: 'publicTest/:companyId',
      element: <PublicLayout />,
      children: [
        {
          path: 'valuesTest',
          children: [
            { path: '/', element: <ValuesTestIntro /> },
            { path: 'answer', element: <ValuesTest /> }
          ]
        }
      ]
    },
    {
      path: 'dashboardSelectiveProcess',
      element: (
        <AuthGuard>
          <DashboardSelectiveProcess />
        </AuthGuard>
      ),
      children: [
        {
          path: '/selectiveProcesses',
          children: [
            { path: '/', element: <ListSelectiveProcesses /> },
            {
              path: '/registerSelectiveProcess',
              element: (
                <RoleGuard>
                  <RegisterSelectiveProcess />
                </RoleGuard>
              )
            },
            {
              path: '/viewSelectiveProcess/:selectiveProcessId',
              children: [
                {
                  path: '/',
                  element: (
                    <RoleGuard>
                      <ManagerSelectiveProcessV2 />
                    </RoleGuard>
                  )
                },
                {
                  path: '/duplicate',
                  element: (
                    <RoleGuard>
                      <DuplicateSelectiveProcess />
                    </RoleGuard>
                  )
                },
                {
                  path: '/update',
                  element: (
                    <RoleGuard>
                      <UpdateSelectiveProcess />
                    </RoleGuard>
                  )
                }
              ]
            }
          ]
        }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Home /> },
        {
          path: 'dashboarding',
          children: [{ path: '/', element: <Dashboarding /> }]
        },
        {
          path: 'user',
          children: [{ path: 'account', element: <EditCompany /> }]
        },
        {
          path: 'candidates',
          children: [
            {
              path: '/',
              element: (
                <RoleGuard>
                  <SearchCandidates />
                </RoleGuard>
              )
            },
            {
              path: '/:candidateId',
              element: (
                <RoleGuard>
                  <ViewCandidate />
                </RoleGuard>
              )
            }
          ]
        },
        {
          path: 'questionSheet',
          children: [
            {
              path: '/',
              element: (
                <RoleGuard>
                  <QuestionSheet />
                </RoleGuard>
              )
            },
            {
              path: '/profileTestResult',
              element: (
                <RoleGuard>
                  <ProfileTestResult />
                </RoleGuard>
              )
            }
          ]
        },
        {
          path: 'helpCenter',
          element: <HelpCenter />
        }
      ]
    },

    {
      path: 'dashboard/questionSheet',
      element: (
        <AuthGuard>
          <DashboardLayout noSidebar />
        </AuthGuard>
      ),
      children: [{ path: '/customTest', element: <CustomTest /> }]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        // { path: 'coming-soon', element: <ComingSoon /> },
        // { path: 'maintenance', element: <Maintenance /> },
        // { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [{ path: '/', element: <LandingPage /> }]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
// const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const ChangePassword = Loadable(lazy(() => import('../pages/authentication/ChangePassword')));
// Dashboard
const Home = Loadable(lazy(() => import('../pages/dashboard/Home')));
const Dashboarding = Loadable(lazy(() => import('../pages/dashboard/Dashboarding')));
const EditCompany = Loadable(lazy(() => import('../pages/dashboard/EditCompany')));
const RegisterSelectiveProcess = Loadable(lazy(() => import('../pages/dashboard/RegisterSelectiveProcess')));
const ListSelectiveProcesses = Loadable(lazy(() => import('../pages/dashboard/ListSelectiveProcesses')));
const UpdateSelectiveProcess = Loadable(lazy(() => import('../pages/dashboard/UpdateSelectiveProcess')));
const DuplicateSelectiveProcess = Loadable(lazy(() => import('../pages/dashboard/DuplicateSelectiveProcess')));

const ManagerSelectiveProcessV2 = Loadable(lazy(() => import('../pages/dashboard/ManagerSelectiveProcessV2')));
const HelpCenter = Loadable(lazy(() => import('../pages/dashboard/helpCenter')));
// Candidate
const SearchCandidates = Loadable(lazy(() => import('../pages/dashboard/Candidate/SearchCandidates')));
const ViewCandidate = Loadable(lazy(() => import('../pages/dashboard/Candidate/ViewCandidate')));
// QuestionSheet
const QuestionSheet = Loadable(lazy(() => import('../pages/dashboard/QuestionSheet')));
const ProfileTestResult = Loadable(lazy(() => import('../pages/dashboard/ProfileTestResult')));
const CustomTest = Loadable(lazy(() => import('../pages/dashboard/CustomTest')));
// Main
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
// Public Tests
const ValuesTestIntro = Loadable(lazy(() => import('../pages/ValuesTest/index')));
const ValuesTest = Loadable(lazy(() => import('../pages/ValuesTest/ValuesTest')));
