// routes
import { PATH_AUTH } from '../../routes/paths';

// ----------------------------------------------------------------------

const menuConfig = [
  {
    title: 'Entrar',
    path: PATH_AUTH.login
  },
  {
    title: 'Sistema do candidato',
    path: process.env.REACT_APP_CANDIDATE_URL
  }
];

export default menuConfig;
