import { Link as RouterLink } from 'react-router-dom';
// material
import { alpha, styled } from '@material-ui/core/styles';
import { Box, Stack, Container } from '@material-ui/core';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Logo from '../../components/logos/Logo';
import AccountPopover from './AccountPopover';
// utils
import NotificationsPopover from './NotificationsPopover';
import { MHidden } from '../../components/@material-extend';
import NavbarSection from '../../components/NavbarSection';
import NavbarConfig from './NavbarConfig';
// hooks
import useAuth from '../../hooks/useAuth';

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72)
}));

// ----------------------------------------------------------------------

export default function DashboardNavbar() {
  const { user } = useAuth();
  const companyId = user?.companyAdmin?.company?.id;
  const isCompanyAnalyst = !!user?.companyAnalyst;

  const shouldShowDashboard = companyId && !isCompanyAnalyst;

  const filteredNavbarConfig = NavbarConfig.filter((item) => shouldShowDashboard || item.title !== 'Dashboard');
  return (
    <RootStyle>
      <Container maxWidth="xl">
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{
            pt: '1.063rem',
            pb: '0.938rem'
          }}
        >
          <Box component={RouterLink} to={`${PATH_DASHBOARD.selectiveProcesses.root}`}>
            <Logo color="dark" />
          </Box>

          <MHidden width="mdDown">
            <NavbarSection navConfig={filteredNavbarConfig} />
          </MHidden>

          <Stack direction="row" alignItems="center" spacing={{ xs: 1, sm: 3 }}>
            <NotificationsPopover />
            <AccountPopover />
          </Stack>
        </Stack>
      </Container>
    </RootStyle>
  );
}
