// ----------------------------------------------------------------------

export default function Tabs(theme) {
  return {
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#F75E44',
          fontFamily: 'Red Hat Display, Public Sans, sans-serif',
          lineHeight: 28 / 18,
          fontSize: 18,
          textTransform: 'none',
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 13,
          paddingBottom: 13,
          marginTop: 5,
          marginBottom: 5,
          fontWeight: theme.typography.fontWeightBold,
          borderTopLeftRadius: theme.shape.borderRadius,
          borderTopRightRadius: theme.shape.borderRadius,
          '&.Mui-selected': {
            color: theme.palette.text.primary
          },
          '&:not(:last-of-type)': {
            marginRight: theme.spacing(8)
          },
          '@media (min-width: 600px)': {
            minWidth: 48
          }
        },
        labelIcon: {
          minHeight: 48,
          flexDirection: 'row',
          '& > *:first-of-type': {
            marginBottom: 0,
            marginRight: theme.spacing(1)
          }
        },
        wrapper: {
          flexDirection: 'row',
          whiteSpace: 'nowrap'
        },
        textColorInherit: {
          opacity: 1,
          color: theme.palette.text.secondary
        }
      }
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: 0
        }
      }
    },
    MuiTabScrollButton: {
      styleOverrides: {
        root: {
          width: 48,
          borderRadius: '50%'
        }
      }
    }
  };
}
