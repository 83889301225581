import { Outlet, Link as RouterLink } from 'react-router-dom';
// material
import { styled, useTheme } from '@material-ui/core/styles';
import { Box, Container, Stack } from '@material-ui/core';
import Logo from '../components/logos/Logo';

// ----------------------------------------------------------------------

const RootStyle = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: '3.75rem',
  paddingBottom: theme.spacing(10),
  backgroundColor: theme.palette.grey[100]
}));

// ----------------------------------------------------------------------

export default function PublicLayout() {
  const theme = useTheme();

  return (
    <RootStyle>
      <Stack direction="row" alignItems="center" sx={{ height: '6rem', backgroundColor: 'white' }}>
        <Container maxWidth="xl">
          <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
            <Logo />
          </Box>
        </Container>
      </Stack>
      <MainStyle
        sx={{
          transition: theme.transitions.create('margin', {
            duration: theme.transitions.duration.complex
          })
        }}
      >
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
